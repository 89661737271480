import React from 'react';
import { FaPhoneAlt, FaMapMarkerAlt, FaEnvelope, FaShare } from 'react-icons/fa';
import { motion } from 'framer-motion';
import bg1 from '../assets/bg1.jpg';
import { CiShare2 } from 'react-icons/ci';
import { FaShareNodes } from 'react-icons/fa6';

const Contact = () => {
  const images = [bg1];
  const bgNum = Math.floor(Math.random() * 1);
  const bgImage = images[bgNum];

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'R.E.E.S',
          text: 'Real Estate Enhancement Services - Turning your real estate into a Real Estate.',
          url: window.location.href,
        });
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      alert('Web Share API is not supported in your browser.');
    }
  };

  return (
    <div className="text-center">
      <motion.div
        className="relative h-96 bg-cover bg-center"
        style={{ backgroundImage: `url(${bgImage})` }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center flex-col text-center">
          <h1 className="text-[#EFF8E2] text-4xl font-bold mb-4 mt-12">Contact Us</h1>
          <motion.div
            whileHover={{ scale: 1.1, translateZ: 50, perspective: 1000, boxShadow: '0px 20px 30px rgba(0, 0, 0, 0.4)', transition: { duration: 0.2 } }}
            className="inline-block mb-4"
          >
            <a href="tel:+15099416012" className="bg-[#1446A0] text-[#EFF8E2] px-4 py-2 rounded font-bold">
              <span>📞</span><span>(509) 941 6012</span>
            </a>
          </motion.div>
        </div>
      </motion.div>
      <div className="container mx-auto px-4 py-8">
        <h2 className="text-2xl font-bold mb-4">Contact Us</h2>
        <div className="flex flex-col items-center space-y-4 mb-8">
          <motion.div
            className="flex items-center space-x-2"
            whileHover={{ scale: 1.1, transition: { duration: 0.3 } }}
          >
            <FaPhoneAlt className="text-[#1446A0]" style={{ verticalAlign: 'middle' }} />
            <a
              href="tel:+15099416012"
              className="bg-[#EFF8E2] text-[#1446A0] px-4 py-2 rounded font-bold flex items-center space-x-2"
              style={{ perspective: '500px' }}
            >
              509-941-6012
            </a>
          </motion.div>
          <motion.div
            className="flex items-center space-x-2"
            whileHover={{ scale: 1.1, transition: { duration: 0.3 } }}
          >
            <FaShareNodes className="text-[#1446A0]" style={{ verticalAlign: 'middle' }} />
            <a
              href="#"
              onClick={handleShare}
              className="bg-[#EFF8E2] text-[#1446A0] px-4 py-2 rounded font-bold flex items-center space-x-2"
              style={{ perspective: '500px' }}
            >
              Share our Website
            </a>
          </motion.div>
          <div className="flex items-center space-x-2">
            <FaMapMarkerAlt className="text-[#1446A0]" style={{ verticalAlign: 'middle' }} />
            <p className="text-lg">20425 Northeast Woodinville Duvall Road Woodinville, WA 98077</p>
          </div>
          <motion.div
            className="flex items-center space-x-2"
            whileHover={{ scale: 1.1, transition: { duration: 0.3 } }}
          >
            <FaEnvelope className="text-[#1446A0]" style={{ verticalAlign: 'middle' }} />
            <a
              href="mailto:keffelerman@gmail.com"
              className="bg-[#EFF8E2] text-[#1446A0] px-4 py-2 rounded font-bold flex items-center space-x-2"
              style={{ perspective: '500px' }}
            >
              keffelerman@gmail.com
            </a>
          </motion.div>
        </div>
        <div className="flex flex-col items-center space-y-4 mb-0">
          <h2 className="text-2xl font-bold">Service Area</h2>
          <p className="text-lg">Duvall, Redmond, Bellevue, Monroe, Bothell, Kenmore, Woodinville, Snohomish, Carnation, Kirkland, Sammamish and surrounding areas.</p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
