import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { FaClock, FaDollarSign } from 'react-icons/fa';
import { PiCertificateFill } from "react-icons/pi";
import bg1 from '../assets/bg1.jpg';
import bg2 from '../assets/bg2.jpg';
import { BsTools } from 'react-icons/bs';

const Home = () => {
  return (
    <div className="text-center" style={{ fontFamily: 'DM Sans' }}>
      <motion.div
        className="relative h-screen bg-cover bg-center"
        style={{ backgroundImage: `url(${bg1})` }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center sm:items-start sm:pl-14 sm:pr-10 sm:py-20">
          <h1 className="text-[#EFF8E2] text-6xl font-bold text-center sm:text-left mr-2 px-3 pb-2" >
            R.E.E.S- Realestate Enhancement Services
          </h1>
          <h3 className="text-[#EFF8E2]  text-2xl pr-2 mb-4 text-center sm:text-left" >
            Turning your real estate into a <span className='italic'>Real Estate</span>.
          </h3>
          <motion.div
            whileHover={{ scale: 1.1, translateZ: 50, perspective: 1000, boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)", transition: { duration: 0.2 } }}
            className="inline-block mb-4 text-center sm:text-left"
          >
            <a href="tel:+15099416012" className="bg-[#EFF8E2] text-[#1446A0] px-4 py-2 rounded font-bold flex items-center space-x-1 text-xl">
              <span>📞</span><span>(509) 941 6012</span>
            </a>
          </motion.div>
        </div>
      </motion.div>

      <motion.div
        className="relative h-96 bg-cover bg-center"
        style={{ backgroundImage: `url(${bg2})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center flex-col pb-6">
          <h3 className="text-[#EFF8E2] text-3xl font-bold px-4 mt-8 text-center" >
            Why Choose Us?
          </h3>
          <div className="mt-4 space-y-4">
            <p className="text-[#EFF8E2] text-lg px-4 text-center" >
              <FaClock className="inline-block w-6 h-6 mr-2 text-[#EFF8E2]" />
              20+ years of experience.
            </p>
            <p className="text-[#EFF8E2] text-lg px-4 text-center" >
              <PiCertificateFill className="inline-block w-6 h-6 mr-2 text-[#EFF8E2]" />
              Licensed & Insured.
            </p>
            <p className="text-[#EFF8E2] text-lg px-4 text-center">
              <FaDollarSign className="inline-block w-6 h-6 mr-2 text-[#EFF8E2] " />
              The best prices & quality.
            </p>
            <p className="text-[#EFF8E2] text-lg px-4 text-center">
              <BsTools className="inline-block w-6 h-6 mr-2 text-[#EFF8E2]" />
              Wide Variety of Services.
            </p>
          </div>
          <div className="mt-8 space-x-4 flex flex-wrap justify-center">
            <motion.div
              whileHover={{ scale: 1.1, translateZ: 50, perspective: 1000, boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)", transition: { duration: 0.2 } }}
              className="inline-block mb-4"
            >
              <Link to="/services" className="bg-[#1446A0] text-[#EFF8E2] px-4 py-2 rounded font-bold" >
                <span>↗️</span>View Services
              </Link>
            </motion.div>
          </div>
        </div>
      </motion.div>
    </div>

  );
};

export default Home;
