import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import bg1 from '../assets/bg1.jpg';
import handyman from '../assets/services/handyman.jpg';
import homeImprovement from '../assets/services/homeimprovement.jpg';
import carpentry from '../assets/services/carpentry.jpg';
import painting from '../assets/services/painting.jpg';
import flooring from '../assets/services/flooring.jpg';
import decks from '../assets/services/decks.jpg';
import additions from '../assets/services/additions.jpg';
import excavation from '../assets/services/excavations.jpg';
import treeWork from '../assets/services/treework.jpg';
import landscaping from '../assets/services/landscaping.jpg';
import estateLiquidation from '../assets/services/estate.jpg';

const Services = () => {
  const images = [bg1];
  const bgNum = Math.floor(Math.random() * 1);
  const bgImage = images[bgNum];

  return (
    <div className="text-center">
      <motion.div
        className="relative h-96 bg-cover bg-center"
        style={{ backgroundImage: `url(${bgImage})` }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center flex-col">
          <h1 className="text-[#EFF8E2] text-4xl font-bold mb-4">Our Services</h1>
          <motion.div
              whileHover={{ scale: 1.1, translateZ: 50, perspective: 1000, boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)", transition: { duration: 0.2 } }}
              className="inline-block mb-4"
            >
            <a href="tel:+15099416012" className="bg-[#1446A0] text-[#EFF8E2] px-4 py-2 rounded font-bold">
              <span>📞</span><span>Call us at (509) 941 6012 to Book</span>
            </a>
          </motion.div>
        </div>
      </motion.div>
      <div className="max-w-4xl mx-auto text-[#EFF8E2] px-4 pt-4 pb-10">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 pt-4">
          <ServiceCard title="Handyman Services" backgroundImage={handyman} />
          <ServiceCard title="Home Improvement" backgroundImage={homeImprovement} />
          <ServiceCard title="Carpentry" backgroundImage={carpentry} />
          <ServiceCard title="Painting" backgroundImage={painting} />
          <ServiceCard title="Flooring" backgroundImage={flooring} />
          <ServiceCard title="Decks" backgroundImage={decks} />
          <ServiceCard title="Additions" backgroundImage={additions} />
          <ServiceCard title="Excavation Services " backgroundImage={excavation} />
          <ServiceCard title="Tree Work" backgroundImage={treeWork} />
          <ServiceCard title="Landscaping" backgroundImage={landscaping} />
          <ServiceCard title="Estate Liquidatation Services" backgroundImage={estateLiquidation} />
        </div>
      </div>
    </div>
  );
};

const ServiceCard = React.forwardRef(({ title, backgroundImage }, ref) => {
  const { ref: inViewRef, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const cardStyle = {
    backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  return (
    <Link to="/contact" className="block" ref={inViewRef}>
      <motion.div
        className="bg-white bg-opacity-25 rounded-md p-4 flex flex-col items-center justify-center text-center hover:bg-opacity-50 transition duration-300 h-40 w-full"
        style={cardStyle}
        initial={{ opacity: 0, y: 50 }}
        animate={inView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.5 }}
        whileHover={{ scale: 1.1, translateZ: 50, perspective: 1000, boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)", transition: { duration: 0.01 } }}
      >
        <h2 className="text-3xl font-semibold mb-2">{title}</h2>
      </motion.div>
    </Link>
  );
});

export default Services;

