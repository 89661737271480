import {React}  from 'react';
import {FaEnvelope} from 'react-icons/fa';

const Footer = () => {
    return (
      <footer className="text-[#1446A0] py-4 bg-[#EFF8E2]" >
      <div className="container mx-auto px-4 flex justify-between items-center">
        <div>
        <p>R.E.E.S- Realestate Enhancement Services &copy; 2024</p>
        <p>Phone: (509) 941-6012</p>
        </div>
        <div className='items-end'>
        <a href="mailto:keffelerman@gmail.com" className="hover:text-gray-800">
          <FaEnvelope size={21}/>
        </a>
        </div>
      </div>
      </footer>
    );
};

export default Footer;
